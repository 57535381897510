<template>
  <li ref="auctions" class="tabsDashboard__tab js-tab-item" data-type="auctions">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div v-if="type === 'flyer'" class="tabsDashboard__tab-text">Auctions <b>bids</b></div>
        <div v-else-if="type === 'provider'" class="tabsDashboard__tab-text"><b>Auctions: offers</b></div>
        <div v-else class="tabsDashboard__tab-text"><b>Auctions:</b> current and historical</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="auction"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/auctions">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__auctions">
        <div v-if="type === 'provider' || type === 'admin'" class="tabsDashboard__auctions-info">
          <span class="icon">
            <SvgIcon name="triangle"/>
          </span>
          <span class="text">Auction in process</span>
        </div>
        <div v-else class="tabsDashboard__auctions-list">
          <div
            v-for="item of auctions"
            :key="item.id"
            class="tabsDashboard__auctions-list-item">
            <div class="tabsDashboard__auctions-list-item-route">
              <div class="tabsDashboard__auctions-list-item-route-from">
                <div class="tabsDashboard__auctions-list-item-route-title">{{item.from}}</div>
                <div class="tabsDashboard__auctions-list-item-route-date">{{item.dateFrom}}</div>
              </div>
              <div class="tabsDashboard__auctions-list-item-route-direction">
                <SvgIcon v-if="!item.oneWay" name="double-long-arrows"/>
                <SvgIcon v-else name="long-left-arrow"/>
              </div>
              <div class="tabsDashboard__auctions-list-item-route-to">
                <div class="tabsDashboard__auctions-list-item-route-title">{{item.to}}</div>
                <div class="tabsDashboard__auctions-list-item-route-date">{{item.dateTo}}</div>
              </div>
            </div>
            <div class="tabsDashboard__auctions-list-item-price">${{item.cost}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['type', 'visible'],
    name: 'TabsDashboardAuctions',
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.auctions)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.auctions.getAttribute('data-type'))
      },
    },
    computed: {
      auctions () {
        return this.$store.state.dashboard.auctions
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
