<template>
  <div class="tabsDashboard__requests-list">
    <div
      v-for="item of requestsList"
      :key="item.id"
      class="tabsDashboard__requests-list-item"
    >
      <div class="tabsDashboard__requests-list-item-info">
        <div class="tabsDashboard__requests-list-item-info-item">
          <router-link
            class="tabsDashboard__requests-list-item-message tabsDashboard__requests-list-item-message--desktop"
            to="/admin/messages"
          >
            <SvgIcon name="mail-footer"/>
          </router-link>
          <router-link
            class="tabsDashboard__requests-list-item-name"
            to="/provider-dashboard/profile"
          >
            {{item.name}}
          </router-link>
          <div class="tabsDashboard__requests-list-item-date">{{item.dateTime}}</div>
        </div>
        <div class="tabsDashboard__requests-list-item-info-item">
          <div class="tabsDashboard__requests-list-item-route">
            <div class="tabsDashboard__requests-list-item-route-from">
              <div class="tabsDashboard__requests-list-item-route-title">{{item.takeOff}}</div>
              <div class="tabsDashboard__requests-list-item-route-date">{{item.from}}</div>
            </div>
            <div class="tabsDashboard__requests-list-item-route-direction">
              <SvgIcon name="long-left-arrow"/>
            </div>
            <div class="tabsDashboard__requests-list-item-route-to">
              <div class="tabsDashboard__requests-list-item-route-title">{{item.land}}</div>
              <div class="tabsDashboard__requests-list-item-route-date">{{item.to}}</div>
            </div>
          </div>
        </div>
        <div class="tabsDashboard__requests-list-item-info-item">
          <div class="tabsDashboard__requests-list-item-pax"><span
            class="tabsDashboard__requests-list-item-pax-caption">No.pax&ensp;</span><span>{{item.pax}}</span></div>
        </div>
      </div>
      <div class="tabsDashboard__requests-list-item-bottom">
        <div class="tabsDashboard__requests-list-item-status">
          <div class="tabsDashboard__requests-list-item-status-item"><b>Manual</b> request</div>
          <div class="tabsDashboard__requests-list-item-status-item">Request status: <b>{{item.status}}</b></div>
        </div>
        <router-link
          class="tabsDashboard__requests-list-item-message tabsDashboard__requests-list-item-message--mobile"
          to="/admin/messages"
        >
          <SvgIcon name="mail-footer"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    name: 'RequestsDashboardAdmin',
    computed: {
      requestsList () {
        return this.$store.state.adminRequests.list.slice(-2)
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
