<template>
  <li ref="messages" class="tabsDashboard__tab js-tab-item" data-type="messages">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">New <b>messages</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="mail"/>
          <span class="tabsDashboard__tab-icon-count">2</span>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/messages">See all</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__messages">
        <div class="tabsDashboard__messages-item">
          <div class="tabsDashboard__messages-header">
            <div class="tabsDashboard__messages-avatar"
                 style="background-image:url('https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg')"></div>
            <div class="tabsDashboard__messages-caption">
              <div class="tabsDashboard__messages-name">John Doe, XYZ</div>
              <div class="tabsDashboard__messages-subject">(Aviation / FLYJETS)</div>
              <div class="tabsDashboard__messages-time">3:30 PM</div>
            </div>
          </div>
          <div class="tabsDashboard__messages-content">
            <div class="tabsDashboard__messages-text">Hi Jessica, The tail number for your upcoming flight is N123XYZ.
              We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018...
            </div>
            <router-link class="tabsDashboard__messages-link" to="#">read more</router-link>
          </div>
        </div>
        <div class="tabsDashboard__messages-item">
          <div class="tabsDashboard__messages-header">
            <div class="tabsDashboard__messages-avatar tabsDashboard__messages-avatar--square"
                 :style="{backgroundImage: `url(${world})`}">
            </div>
            <div class="tabsDashboard__messages-caption">
              <div class="tabsDashboard__messages-name">Message center</div>
              <div class="tabsDashboard__messages-subject">Response to your appeal</div>
              <div class="tabsDashboard__messages-time">1:30 PM</div>
            </div>
          </div>
          <div class="tabsDashboard__messages-content">
            <div class="tabsDashboard__messages-text">Hi Jessica, The tail number for your upcoming flight is N123XYZ.
              We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018...
            </div>
            <router-link class="tabsDashboard__messages-link" to="#">read more</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See all</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['visible'],
    name: 'TabsDashboardMessages',
    data: () => ({
      world: require('@/assets/img/dashboard/world.png'),
    }),
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.messages)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.messages.getAttribute('data-type'))
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
