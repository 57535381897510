<template>
  <li ref="flights" class="tabsDashboard__tab js-tab-item" data-type="flights">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>

    <div v-if="type === 'flyer'">
      <div class="tabsDashboard__tab-header">
        <div class="tabsDashboard__tab-title">
          <div class="tabsDashboard__tab-text"><b>Flights</b> upcoming and history</div>
          <div class="tabsDashboard__tab-icon">
            <SvgIcon name="distance"/>
          </div>
        </div>
        <router-link class="tabsDashboard__tab-link" to="/dashboard/flights">See all</router-link>
      </div>
      <div class="tabsDashboard__tab-inner">
        <div class="tabsDashboard__flights">
          <div class="tabsDashboard__flights-item">
            <div class="tabsDashboard__flights-img"
                 style="background-image: url('https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602196321/FlyJets/plane_oadvwk.jpg')"></div>
            <div class="tabsDashboard__flights-from">
              <div class="tabsDashboard__flights-arrow">
                <SvgIcon name="decor-line-3"/>
              </div>
              <div class="tabsDashboard__flights-title">New York</div>
              <div class="tabsDashboard__flights-text">4:30 PM - 6:30 PM<br>APR 24, JFK</div>
              <div class="tabsDashboard__flights-date">Monday, August 20, 2018</div>
              <div class="tabsDashboard__flights-info">Departs: 3:00pm EST, 15:00:00 EST</div>
            </div>
            <div class="tabsDashboard__flights-separator">2H35MIN</div>
            <div class="tabsDashboard__flights-to">
              <div class="tabsDashboard__flights-title">Orlando FL</div>
              <div class="tabsDashboard__flights-text">4:30 PM - 6:30 PM<br>APR 24, JFK</div>
              <div class="tabsDashboard__flights-date">Tuesday, August 28, 2018</div>
              <div class="tabsDashboard__flights-info">Departs: 11 AM EST, 11:00:00 EST</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabsDashboard__tab-footer">
        <router-link class="tabsDashboard__tab-link forMobile" to="">See all</router-link>
      </div>
    </div>

    <div v-else-if="type === 'provider' || type === 'admin'">
      <div class="tabsDashboard__tab-header">
        <div class="tabsDashboard__tab-title">
          <div class="tabsDashboard__tab-text"><b>Upcoming</b> and <b>historical</b> charters and flights</div>
          <div class="tabsDashboard__tab-icon"><SvgIcon name="distance"/></div>
        </div>
      </div>
      <div class="tabsDashboard__tab-inner">
        <div class="tabsDashboard__flights" :class="`tabsDashboard__flights--${type}`">
          <router-link class="tabsDashboard__flights-item" to="">
            <div class="tabsDashboard__flights-circle tabsDashboard__flights-circle--upcoming">
              <div class="tabsDashboard__flights-circle-line">
                <svg width="136" height="136" viewBox="0 0 136 136" fill="none">
                  <circle cx="68" cy="68" r="67.5" stroke="url(#upcoming)" stroke-dasharray="3 3"/>
                  <defs>
                    <linearGradient id="upcoming" x1="68" y1="0" x2="68" y2="136" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#292931"/>
                      <stop offset="1" stop-color="#45454F"/>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                class="tabsDashboard__flights-circle-inner"><span
                class="tabsDashboard__flights-circle-inner-value">17</span></div>
            </div>
            <div class="tabsDashboard__flights-title">Upcoming flights</div>
            <div class="tabsDashboard__flights-link">See flights</div>
          </router-link>
          <router-link class="tabsDashboard__flights-item" to="">
            <div class="tabsDashboard__flights-circle tabsDashboard__flights-circle--active">
              <div class="tabsDashboard__flights-circle-line">
                <svg width="136" height="136" viewBox="0 0 136 136" fill="none">
                  <circle cx="68" cy="68" r="67.5" stroke="url(#active)" stroke-dasharray="3 3"/>
                  <defs>
                    <linearGradient id="active" x1="68" y1="0" x2="68" y2="136" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#41FB96"/>
                      <stop offset="1" stop-color="#12A053"/>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                class="tabsDashboard__flights-circle-inner"><span
                class="tabsDashboard__flights-circle-inner-value">2</span></div>
            </div>
            <div class="tabsDashboard__flights-title">Active flights</div>
            <div class="tabsDashboard__flights-link">See flights</div>
          </router-link>
          <router-link class="tabsDashboard__flights-item" to="">
            <div class="tabsDashboard__flights-circle tabsDashboard__flights-circle--past">
              <div class="tabsDashboard__flights-circle-line">
                <svg width="136" height="136" viewBox="0 0 136 136" fill="none">
                  <circle cx="68" cy="68" r="67.5" stroke="url(#past)" stroke-dasharray="3 3"/>
                  <defs>
                    <linearGradient id="past" x1="68" y1="0" x2="68" y2="136" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#292931"/>
                      <stop offset="1" stop-color="#45454F"/>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                class="tabsDashboard__flights-circle-inner"><span
                class="tabsDashboard__flights-circle-inner-value">5</span></div>
            </div>
            <div class="tabsDashboard__flights-title">Past flights</div>
            <div class="tabsDashboard__flights-link">See flights</div>
          </router-link>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['type', 'visible'],
    name: 'TabsDashboardFlights',
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.flights)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.flights.getAttribute('data-type'))
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
