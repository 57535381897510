<template>
  <li ref="flyRewards" class="tabsDashboard__tab js-tab-item" data-type="flyRewards">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div v-if="type === 'admin'" class="tabsDashboard__tab-text">FLY<b>Rewards</b>&ensp;uploads</div>
        <div v-else class="tabsDashboard__tab-text">FLY<b>Rewards</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="giftbox"/>
        </div>
      </div>
      <router-link
        v-if="type === 'flyer'"
        class="tabsDashboard__tab-link"
        to="/dashboard/flyrewards"
      >See full
      </router-link>
      <router-link
        v-else-if="type === 'provider'"
        class="tabsDashboard__tab-link"
        to="/provider-dashboard/flyrewards"
      >See full
      </router-link>
      <router-link
        v-else
        class="tabsDashboard__tab-link"
        to="/admin/flyrewards"
      >See full
      </router-link>

    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__flyRewards">
        <div v-if="type === 'flyer' || type === 'provider'" class="flyRewardsDashboard__available">
          <div class="flyRewardsDashboard__available-title">Available fly<b>rewards</b></div>
          <div class="flyRewardsDashboard__available-circle">
            <div class="flyRewardsDashboard__available-circle-line flyRewardsDashboard__available-circle-line--1">
              <svg width="223" height="223" viewBox="0 0 223 223" fill="none">
                <circle opacity="0.4" cx="111.5" cy="111.5" r="111" stroke="url(#available-line-01)"
                        stroke-dasharray="3 3"/>
                <path
                  d="M155.837 212.977C156.064 213.481 156.703 213.724 157.208 213.497L160.674 211.936L166.095 215.56L167.355 214.992L163.51 210.66L166.976 209.099L168.489 209.934L169.434 209.509L167.811 207.586L167.449 205.097L166.503 205.522L166.125 207.208L162.659 208.769L161.965 203.018L160.704 203.585L159.823 210.046L156.356 211.606C155.852 211.833 155.61 212.473 155.837 212.977Z"
                  fill="url(#available-line-11)"/>
                <defs>
                  <linearGradient id="available-line-01" x1="111.5" y1="0" x2="111.5" y2="223"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                  <linearGradient id="available-line-11" x1="164.834" y1="216.127" x2="159.444" y2="204.152"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="flyRewardsDashboard__available-circle-line flyRewardsDashboard__available-circle-line--2">
              <svg width="187" height="187" viewBox="0 0 187 187" fill="none">
                <circle cx="93.5" cy="93.5" r="93" stroke="url(#available-line-02)" stroke-dasharray="3 3"/>
                <path
                  d="M54.9477 8.89256C54.6194 8.2988 53.8127 8.06647 53.2189 8.39472L49.1368 10.6514L42.1432 6.76535L40.6588 7.58598L45.7968 12.4978L41.7147 14.7546L39.7808 13.8856L38.6674 14.5011L40.8457 16.6885L41.5396 19.6965L42.6529 19.0811L42.9456 16.9812L47.0278 14.7245L48.4547 21.6879L49.9392 20.8673L50.3677 12.8781L54.4498 10.6213C55.0436 10.2931 55.2759 9.48633 54.9477 8.89256Z"
                  fill="url(#available-line-12)"/>
                <defs>
                  <linearGradient id="available-line-02" x1="93.5" y1="0" x2="93.5" y2="187"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                  <linearGradient id="available-line-12" x1="43.6276" y1="5.94473" x2="51.4236" y2="20.0467"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292931"/>
                    <stop offset="1" stop-color="#45454F"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="flyRewardsDashboard__available-circle-inner">
              <div class="flyRewardsDashboard__available-circle-inner-value">550</div>
            </div>
          </div>
        </div>
        <div v-if="type === 'flyer' || type === 'provider'" class="flyRewardsDashboard__info">
          <span><b>1</b>&nbsp;fly<b>reward</b></span><span> = </span><span><b>$1</b></span>
        </div>
        <div v-if="type === 'flyer' || type === 'provider'" class="flyRewardsDashboard__currency">
          <div class="flyRewardsDashboard__currency-title"><b>Currency ($)</b> Equivalent</div>
          <div class="flyRewardsDashboard__currency-circle">
            <div class="flyRewardsDashboard__currency-circle-line">
              <svg width="189" height="189" viewBox="0 0 189 189" fill="none">
                <circle cx="94.5" cy="94.5" r="94" stroke-dasharray="3 3"></circle>
              </svg>
            </div>
            <div class="flyRewardsDashboard__currency-circle-inner">$550.00</div>
          </div>
        </div>

        <div v-if="type === 'admin'" class="tabsDashboard__flyRewards-table">
          <div class="tabsDashboard__flyRewards-table-header">
            <div class="tabsDashboard__flyRewards-table-cell">№</div>
            <div class="tabsDashboard__flyRewards-table-cell">Name</div>
            <div class="tabsDashboard__flyRewards-table-cell">Fly<b>Rewards</b></div>
          </div>
          <div class="tabsDashboard__flyRewards-table-body">
            <div
              class="tabsDashboard__flyRewards-table-row"
              v-for="(item, index) of rewardsList"
              :key="item.id"
            >
              <div class="tabsDashboard__flyRewards-table-row-left">
                <div class="tabsDashboard__flyRewards-table-cell">
                  <div class="tabsDashboard__flyRewards-table-index">{{index + 1}}</div>
                </div>
                <div class="tabsDashboard__flyRewards-table-cell">
                  <a class="tabsDashboard__flyRewards-table-name">{{item.name}}</a>
                </div>
                <div class="tabsDashboard__flyRewards-table-cell">
                  <div class="tabsDashboard__flyRewards-table-count">{{item.flyRewards}}</div>
                  <div class="tabsDashboard__flyRewards-table-added">+50</div>
                  <div class="tabsDashboard__flyRewards-table-cancel">
                    <SvgIcon name="cancel"/>
                  </div>
                </div>
              </div>
              <div class="tabsDashboard__flyRewards-table-row-right">
                <form
                  class="form form--addFlyrewards js-addFlyrewardsForm"
                  data-href="/"
                  data-action="addFlyrewards"
                  @submit.prevent="submitHandler(item.id)"
                >
                  <label class="form__label form__label--number">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-numberOnly"
                        type="number"
                        name="flyrewards"
                        placeholder="50"
                        autocomplete="off"
                        required="required"
                      />
                    </div>
                  </label>
                  <div class="form__label form__label--button">
                    <button class="form__button">
                      <span class="text js-addFlyrewards-text">Add</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './TabsDashboardFlyRewards.styl'

  export default {
    props: ['type', 'visible'],
    name: 'TabsDashboardFlyRewards',
    computed: {
      rewardsList () {
        return this.$store.state.flyRewards.list
      },
    },
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.flyRewards)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.flyRewards.getAttribute('data-type'))
      },
      submitHandler (item) {
        const field = event.target.querySelector('.js-numberOnly').value

        const data = {
          id: item,
          flyRewards: field,
        }
        console.log(data)
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
