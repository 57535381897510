<template>
  <li ref="requests" class="tabsDashboard__tab js-tab-item" data-type="requests">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div v-if="type === 'flyer' || type === 'provider'" class="tabsDashboard__tab-text">Requests</div>
        <div v-else class="tabsDashboard__tab-text">Flight <b>requests</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon v-if="type === 'flyer' || type === 'provider'" name="discuss"/>
          <SvgIcon v-else name="calendar-aircraft"/>
          <span v-if="type === 'admin'" class="tabsDashboard__tab-icon-count js-sign-up-request-count">2</span>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/requests">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__requests">
        <RequestsDashboardList v-if="type === 'flyer' || type === 'provider'" :miniTab="true" :type="type"/>
        <RequestsDashboardAdmin v-else/>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import RequestsDashboardList from '@/components/dashboard/RequestsDashboardList/RequestsDashboardList'
  import RequestsDashboardAdmin from '@/components/dashboard/RequestsDashboardAdmin/RequestsDashboardAdmin'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './TabsDashboardRequests.styl'

  export default {
    props: ['type', 'miniTab', 'visible'],
    name: 'TabsDashboardRequests',
    data: () => ({
      options: ['catering', 'other'],
    }),
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.requests)
      },
    },
    computed: {
      requests () {
        return this.$store.state.dashboard.requests
      },
    },
    mounted () {
      this.$root.fields.changeChecked('.form__checkbox')
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.requests.getAttribute('data-type'))
      },
      toggleEdit (event) {
        const item = event.currentTarget.closest('.requestsDashboard__list-item')

        if (item.classList.contains('edit')) {
          item.classList.remove('edit')
          item.querySelectorAll('.js-route-value').forEach(item => {
            item.style.display = 'block'
          })
          item.querySelectorAll('textarea').forEach(item => {
            item.style.display = 'none'
            item.setAttribute('disabled', 'disabled')
          })
        } else {
          item.classList.add('edit')
          item.querySelectorAll('.js-route-value').forEach(item => {
            item.style.display = 'none'
          })
          item.querySelectorAll('textarea').forEach(item => {
            item.style.display = 'block'
            item.removeAttribute('disabled')
          })
        }
      },
      saveChanges (event) {
        const item = event.currentTarget.closest('.requestsDashboard__list-item')

        item.querySelectorAll('textarea').forEach(item => {
          item.closest('.js-route-label').querySelector('.js-route-value').innerHTML = item.value
        })

        item.classList.remove('edit')
        item.querySelectorAll('.js-route-value').forEach(item => {
          item.style.display = 'block'
        })
        item.querySelectorAll('textarea').forEach(item => {
          item.style.display = 'none'
          item.setAttribute('disabled', 'disabled')
        })
      },
      submitHandler (event) {
        event.target.classList.add('loading')
      },
    },
    components: {
      SvgIcon,
      RequestsDashboardList,
      RequestsDashboardAdmin,
    },
  }
</script>
