<template>
  <li ref="profile" class="tabsDashboard__tab js-tab-item" data-type="profile">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">Profile</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="user2"/>
        </div>
      </div>
      <router-link
        v-if="type === 'flyer'"
        class="tabsDashboard__tab-link"
        to="/dashboard/profile"
      >See full</router-link>
      <router-link
        v-else-if="type === 'provider'"
        class="tabsDashboard__tab-link"
        to="/provider-dashboard/profile"
      >See full</router-link>
      <router-link
        v-else
        class="tabsDashboard__tab-link"
        to="/admin/profile"
      >See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__profile">
        <div class="tabsDashboard__profile-top">
          <div class="tabsDashboard__profile-avatar">
            <div class="tabsDashboard__profile-avatar-img"
                 style="background-image: url('https://res.cloudinary.com/dyyrf9gqp/image/upload/v1601236934/FlyJets/team/Jessica_xle8fa.jpg')"
            >
            </div>
          </div>
          <div class="tabsDashboard__profile-info">
            <div class="tabsDashboard__profile-info-item"><span
              class="tabsDashboard__profile-info-title">Name:&ensp;</span><span>Jessica Leigh Fisher</span></div>
            <div class="tabsDashboard__profile-info-item"><span
              class="tabsDashboard__profile-info-title">Birth:&ensp;</span><span>06/03/1995</span></div>
            <div class="tabsDashboard__profile-info-item"><span
              class="tabsDashboard__profile-info-title">Email:&ensp;</span><span>jessica@flyjets.com</span></div>
            <div class="tabsDashboard__profile-info-item">
              <span v-if="type === 'flyer' || type === 'admin'"
                    class="tabsDashboard__profile-info-title">Phone:&ensp;</span>
              <span v-else-if="type === 'provider'" class="tabsDashboard__profile-info-title">Work phone:&ensp;</span>
              <span>+7&nbsp;444-78-65</span>
            </div>
          </div>
        </div>
        <div class="tabsDashboard__profile-bottom">
          <div class="tabsDashboard__profile-info">
            <div v-if="type === 'flyer' || type === 'admin'" class="tabsDashboard__profile-info-item">
              <span class="tabsDashboard__profile-info-title">Address:&ensp;</span>
              <span>Lenina&nbsp;45, Appartment&nbsp;7, Rostov-on-Don. 344&nbsp;067</span>
            </div>
            <div v-else-if="type === 'provider'">
              <div class="tabsDashboard__profile-info-item">
                <span class="tabsDashboard__profile-info-title">Company name:&ensp;</span>
                <span>FlyJets</span>
              </div>
              <div class="tabsDashboard__profile-info-item">
                <span class="tabsDashboard__profile-info-title">Work address:&ensp;</span>
                <span><i>Street name and number Suite and/or Unit Number City State Zip</i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['type', 'visible'],
    name: 'TabsDashboardProfile',
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.profile)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.profile.getAttribute('data-type'))
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
